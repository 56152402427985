<template>
  <div id="settings">

    <PageHeader heading="Settings" />

    <b-row class="mt-2">
      <b-col cols="6">
        <b-form>
          <SettingTextInput stateKey="apiKey" stateKeyHuman="API Key" labelValue="Fastly API Key:" ref="apiKey"></SettingTextInput>
          <b-button @click="saveSettings" variant="primary" class="mr-1">Save Settings</b-button>
          <b-button @click="resetSettings" variant="danger" class="mr-1">Reset Settings</b-button>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import SettingTextInput from '@/components/SettingTextInput'

export default {
  name: 'settings',
  components: {
    PageHeader,
    SettingTextInput,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    resetSettings() {
      this.$refs.apiKey.clearValue()
      this.$store.dispatch('clearSettingsAction')
    },
    saveSettings() {
      this.$refs.apiKey.updateState()
    }
  },
}
</script>
