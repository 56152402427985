<template>
  <b-form-group>
    <label
      :for="controlId"
    >
      {{ labelValue }}
    </label>
    <b-form-input
      :id="controlId"
      v-model="newValue"
      debounce="500"
      :disabled="disableInput"
      @input="updateState"
    ></b-form-input>
    <b-form-text
      :id="controlHelpId"
      v-if="disableInput"
    >
      If the {{ stateKeyHuman }} was set as an environment variable, you cannot change it here.
    </b-form-text>
  </b-form-group>
</template>

<script>
export default {
  name: 'setting-text-input',
  props: {
    stateKey: String,
    stateKeyHuman: String,
    labelValue: String,
  },
  computed: {
    controlId() {
      return `${this.stateKey}__input`
    },
    controlHelpId() {
      return `${this.stateKey}__input--help`
    },
    disableInput() {
      return this.$store.state[`${this.stateKey}FromEnv`]
    },
  },
  data() {
    return {
      newValue: this.$store.state[this.stateKey]
    }
  },
  methods: {
    updateState() {
      // if this.stateKey = "ghToken", the dispatch action is "setGhTokenAction"
      const transformedStateKey = this.stateKey.charAt(0).toUpperCase() + this.stateKey.slice(1)
      this.$store.dispatch(`set${transformedStateKey}Action`, this.newValue)
    },
    clearValue() {
      this.newValue = null
    },
  }
}
</script>
